<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="4"
        cols="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Total Keseluruhan</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                key="DollarSignIcon"
                md="12"
                sm="12"
                class="mb-2 mb-md-0"
              >
                <b-col
                  key="ActivityIcon"
                  md="12"
                  sm="12"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="primary"
                      >
                        <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        Total Keuangan
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ dataDashboardKeuangan.keuangan_total | formatRupiah }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="8"
        cols="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Bulan Ini</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                key="DollarSignIcon"
                md="6"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-col
                  key="ActivityIcon"
                  md="12"
                  sm="12"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="info"
                      >
                        <feather-icon
                          size="24"
                          icon="ActivityIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        Pemasukan
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ dataDashboardKeuangan.pemasukan_bulan_ini | formatRupiah }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-col>
              <b-col
                key="DollarSignIcon"
                md="6"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="danger"
                    >
                      <feather-icon
                        size="24"
                        icon="ActivityIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Pengeluaran
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ dataDashboardKeuangan.pengeluaran_bulan_ini | formatRupiah }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              class="mr-1"
              variant="primary"
              @click="addKeuanganSidebar = true"
            >
              + Tambah Data Keuangan
            </b-button>
            <b-button
              variant="primary"
              @click="exportKeuanganModal = true"
            >
              Export Data
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Bis -->
        <template #cell(id_bis)="data">
          <span class="text-danger font-weight-bolder">
            {{ data.item.bis !== null ? data.item.bis.nama : '' }}
          </span>
        </template>

        <!-- Column: Jenis -->
        <template #cell(jenis)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>

        <!-- Column: Tipe -->
        <template #cell(tipe)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Image -->
        <template #cell(foto_nota)="data">
          <b-img
            v-if="data.value"
            :src="data.value"
            height="125"
            alt="Responsive image"
          />
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`Keuangan-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editKeuangan(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`Keuangan-row-${data.item.id}-edit-icon`"
              />

              <!-- <b-button
                :id="`Keuangan-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`Keuangan-row-${data.item.id}-hapus-icon`"
              /> -->
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalKeuangan"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-1"
      v-model="exportKeuanganModal"
      title="Export Data"
      centered
      ok-only
      ok-title="Export"
      @ok="exportDataKeuangan()"
      @close="resetExportData()"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex mb-1"
        >
          <label
            class="mr-1"
          >Tahun: </label>
          <date-picker
            v-model="exportData.tahun"
            value-type="format"
            format="YYYY"
            type="year"
            placeholder="Pilih Tahun"
          />
        </b-col>
        <b-col
          cols="12"
          class="d-flex"
        >
          <label
            class="mr-1"
          >Bulan: </label>
          <date-picker
            v-model="exportData.bulan"
            value-type="format"
            format="MM"
            type="month"
            placeholder="Pilih Bulan"
          />
        </b-col>
      </b-row>
    </b-modal>

    <AddKeuanganContent
      v-model="addKeuanganSidebar"
      :keuangan="dataEditKeuangan"
      @keuangan-sidebar-change="editKeuangan"
      @submit-data-keuangan="submitData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BCardHeader,
  BPagination, BTooltip, BButtonGroup, BImg, BModal, BMedia, BMediaAside, BAvatar, BMediaBody,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import AddKeuanganContent from './Add.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BButtonGroup,
    BImg,
    BModal,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    AddKeuanganContent,
    vSelect,
    DatePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Bis',
          key: 'id_bis',
        },
        {
          label: 'Jenis',
          key: 'jenis',
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
        },
        {
          label: 'Tipe',
          key: 'tipe',
        },
        {
          label: 'Jumlah',
          key: 'jumlah',
        },
        {
          label: 'Foto Nota',
          key: 'foto_nota',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      totalKeuangan: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      searchTerm: '',
      addKeuanganSidebar: false,
      exportKeuanganModal: false,
      exportData: {
        tahun: null,
        bulan: null,
      },
      dataEditKeuangan: {
        id: '1',
        keterangan: null,
        jenis: null,
        jumlah: null,
        tanggal: null,
        foto_nota: null,
      },
      dataDashboardKeuangan: {
        keuangan_total: 0,
        pemasukan_bulan_ini: 0,
        pengeluaran_bulan_ini: 0,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      if (this.statusFilter !== null) {
        const statusFilter = this.statusFilter === 'Aktif' ? 'aktif' : 'tidak aktif'

        return this.rowsData.filter(data => data.status === statusFilter)
      }
      return this.rowsData
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // eslint-disable-next-line no-return-assign
      this.$http.get('/keuangan').then(response => {
        this.dataDashboardKeuangan = response.data.data.data_dashboard_keuangan
        this.rowsData = response.data.data.data_keuangan

        this.totalKeuangan = response.data.data.data_keuangan.length
      })
    },
    editKeuangan(data, value) {
      this.dataEditKeuangan = data
      this.addKeuanganSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('jenis', data.jenis)
        formData.append('keterangan', data.keterangan)
        formData.append('jumlah', data.jumlah)
        formData.append('tipe', data.tipe)
        formData.append('foto_nota', data.foto_nota)

        this.$http.post('/keuangan', formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      } else {
        const formData = new FormData()
        formData.append('jenis', data.jenis)
        formData.append('keterangan', data.keterangan)
        formData.append('jumlah', data.jumlah)
        formData.append('tipe', data.tipe)
        formData.append('foto_nota', data.foto_nota)
        formData.append('tanggal', data.tanggal)
        // formData.append('_method', 'PUT')

        this.$http.post(`/keuangan/${data.id}`, formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      }
      this.addKeuanganSidebar = false
    },
    resetExportData() {
      this.exportData = {
        tahun: null,
        bulan: null,
      }
    },
    exportDataKeuangan() {
      this.$http.get(`keuangan-export?tahun=${this.exportData.tahun}&bulan=${this.exportData.bulan ? this.exportData.bulan : ''}`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExportKeuangan.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
