<template>
  <div>
    <b-sidebar
      id="add-keuangan-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addKeuanganSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ keuanganLocal.id > 0 ? 'Edit Keuangan' : 'Add Keuangan' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Keterangan"
                  label-for="keterangan-keuangan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Keterangan"
                    rules="required"
                  >
                    <b-form-input
                      id="keterangan-keuangan"
                      v-model="keuanganLocal.keterangan"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Keterangan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jenis"
                  label-for="jenis-keuangan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jenis"
                    rules="required"
                  >
                    <b-form-input
                      id="jenis-keuangan"
                      v-model="keuanganLocal.jenis"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jenis"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah-keuangan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah-keuangan"
                      v-model="keuanganLocal.jumlah"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Tipe"
                  label-for="tipe-keuangan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipe"
                    rules="required"
                  >
                    <b-form-select
                      id="tipe-keuangan"
                      v-model="keuanganLocal.tipe"
                      :options="options"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Foto Nota"
                  label-for="foto-nota"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Foto Nota"
                    rules="required"
                  >
                    <b-form-file
                      id="foto-nota"
                      v-model="keuanganLocal.foto_nota"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormFile,
    BFormSelect,

  },
  model: {
    prop: 'addKeuanganSidebar',
  },
  props: {
    addKeuanganSidebar: {
      type: Boolean,
      required: true,
    },
    keuangan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      options: [
        { value: 'pemasukan', text: 'Pemasukan' },
        { value: 'pengeluaran', text: 'Pengeluaran' },
      ],
      keuanganLocal: {
        id: null,
        keterangan: null,
        jumlah: null,
        jenis: null,
        tipe: null,
        foto_nota: null,
        tanggal: null,
      },
    }
  },
  watch: {
    keuangan() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.keuanganLocal = {
        id: this.keuangan.id,
        keterangan: this.keuangan.keterangan,
        jumlah: this.keuangan.jumlah,
        jenis: this.keuangan.jenis,
        tipe: this.keuangan.tipe,
        tanggal: this.keuangan.tanggal,
        foto_nota: this.keuangan.foto_nota,
      }
    },
    changeValue() {
      const data = {
        id: null,
        keterangan: null,
        jumlah: null,
        jenis: null,
        tipe: null,
        tanggal: null,
        foto_nota: null,
      }
      this.$emit('keuangan-sidebar-change', data, false)
    },
    submit() {
      if (this.keuanganLocal.id > 0) {
        this.$emit('submit-data-keuangan', this.keuanganLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-keuangan', this.keuanganLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
